<template>
  <div>
    <campaign-simple></campaign-simple>
  </div>
</template>

<script>
import CampaignSimple from './../../../components/bulksms/campaigns/simple';
export default {
  data: () => ({
  }),
  components: {
    CampaignSimple
  }
}
</script>
<template>
  <v-dialog v-model="dialog.display" max-width="350">
    <div>
      <v-tabs centered color="primary" icons-and-text>
        <v-tabs-slider color="red"></v-tabs-slider>
        <v-tab href="#date" class="font-weight-bold">
          {{ $t('date') }}
          <v-icon>mdi-calendar</v-icon>
        </v-tab>

        <v-tab href="#hour" class="font-weight-bold">
          {{ $t('hour') }}
          <v-icon>mdi-clock-outline</v-icon>
        </v-tab>

        <v-tab-item value="date">
          <v-date-picker
            v-model="datetime.date"
            style="height: 380px;"
            color="primary"
            :locale="$utils.setLocaleBCP47($i18n.locale)"
            full-width
            :min="$moment().format('YYYY-MM-DD')"
          ></v-date-picker>
        </v-tab-item>
        <v-tab-item value="hour">
          <v-time-picker
            :locale="$utils.setLocaleBCP47($i18n.locale)"
            v-model="datetime.hour"
            color="primary"
            format="24hr"
            full-width
          ></v-time-picker>
        </v-tab-item>
      </v-tabs>
    </div>
    <div>
      <v-card elevation="0" class="py-0 my-0">
        <v-divider></v-divider>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn text @click="clear()" class="font-weight-bold">{{ $t('btn.clear') }}</v-btn>
          <v-btn text color="secondary" class="font-weight-bold" @click="confirm()">{{ $t('btn.ok') }}</v-btn>
        </v-card-actions>
      </v-card>
    </div>
  </v-dialog>
</template>

<script>
export default {
  props: {
    dialog: {
      type: Object,
      default: function() {
        return {
          display: false
        };
      }
    },
    datetime: {
      type: Object,
      default: function() {
        return {
          date: null,
          hour: null
        };
      }
    }
  },
  data: () => ({}),
  methods: {
    clear() {
      this.dialog.display = false;
      this.$emit("changed", null);
    },
    confirm() {
      this.dialog.display = false;
      if (
        this.isValidDate(this.datetime.date) &&
        this.isValidTime(this.datetime.hour)
      ) {
        this.$emit("changed", this.datetime);
      }
    },
    isValidDate(date) {
      return this.$moment(date, "YYYY-MM-DD").isValid();
    },
    isValidTime(hour) {
      return this.$moment(hour, "HH-mm").isValid();
    }
  }
};
</script>
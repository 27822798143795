<template>
  <v-dialog max-width="500" v-model="dialog.display">
    <v-card>
      <v-card-title>
        <div class="text-h6 font-weight-medium">{{ $t('bulksms.campaign.simple.confirm.title') }}</div>
      </v-card-title>
      <v-divider></v-divider>
      <v-card-text class="px-4 pt-2 mt-0 pb-0">
        <v-container fluid grid-list-md v-if="!_.isEmpty(push)">
          <v-row align="center" justify="center" v-if="accountIsDisabled">
            <v-col cols="12">
              <v-alert type="warning" class="text-body-1 py-3">{{ $t('bulksms.error.disabled') }}</v-alert>
            </v-col>
          </v-row>
          <v-row align="center" justify="center" v-else-if="!creditIsSufficient">
            <v-col cols="12">
              <v-alert type="warning" class="text-body-1 py-3">{{ $t('bulksms.error.credit') }}</v-alert>
            </v-col>
          </v-row>
          <v-row align="center" justify="center" class="py-2">
            <v-col cols="12" sm="8">
              <div class="d-flex align-center">
                <v-icon class="mr-2">mdi-clock-outline</v-icon>
                <span
                  class="text-subtitle-2 text--primary font-weight-bold"
                >{{ $t('bulksms.campaign.fields.send_date.placeholder') }}</span>
              </div>
            </v-col>
            <v-col cols="12" sm="4">
              <div class="text-right">
                <v-chip color="fourth" class="table-chips mr-0">
                  <span class="text-body-2 font-weight-bold">
                    <span v-if="push.sendDate">{{ push.sendDate }}</span>
                    <span v-else>{{ $t('now') }}</span>
                  </span>
                </v-chip>
              </div>
            </v-col>
          </v-row>
          <v-divider></v-divider>
          <v-row align="center" class="py-2" justify="center">
            <v-col cols="12" sm="8">
              <div class="d-flex align-center">
                <v-icon class="mr-2">mdi-account-arrow-right-outline</v-icon>
                <span
                  class="text-subtitle-2 text--primary font-weight-bold"
                >{{ $t('bulksms.campaign.fields.sender.placeholder') }}</span>
              </div>
            </v-col>
            <v-col cols="12" sm="4">
              <div class="text-right">
                <v-chip color="fourth" class="table-chips mr-0">
                  <span class="text-body-2 font-weight-bold">
                    <span>{{ push.sender }}</span>
                  </span>
                </v-chip>
              </div>
            </v-col>
          </v-row>
          <v-divider></v-divider>
          <v-row align="center" class="py-2" justify="center">
            <v-col cols="12" sm="8">
              <div class="d-flex align-center">
                <v-icon class="mr-2">mdi-contacts-outline</v-icon>
                <span
                  class="text-subtitle-2 text--primary font-weight-bold"
                >{{ $t('bulksms.campaign.fields.numbers.placeholder') }}</span>
              </div>
            </v-col>
            <v-col cols="12" sm="4">
              <div class="text-right">
                <v-chip color="fourth" class="table-chips mr-0">
                  <span class="text-body-2 font-weight-bold">
                    <span>{{ numbersCount(numbers) }}</span>
                  </span>
                </v-chip>
              </div>
            </v-col>
          </v-row>
          <v-divider></v-divider>
          <v-row align="center" justify="center" class="py-2">
            <v-col cols="12" sm="8">
              <div class="d-flex align-center">
                <v-icon class="mr-2">mdi-counter</v-icon>
                <span
                  class="text-subtitle-2 font-weight-bold"
                >{{ $t('bulksms.campaign.fields.sms_size.title') }}</span>
              </div>
            </v-col>
            <v-col cols="12" sm="4">
              <div class="text-right">
                <v-chip color="fourth" class="table-chips mr-0">
                  <span class="text-body-2 font-weight-bold">
                    <span>{{ $utils.pad($utils.countSms(push.content), 2) }} SMS</span>
                  </span>
                </v-chip>
              </div>
            </v-col>
          </v-row>
          <v-divider></v-divider>
          <v-row align="center" justify="center" class="py-2">
            <v-col cols="12">
              <div class="text-justify font-weight-bold text-subtitle-2 grey--text text--darken-2">
                <span>{{ push.content }}</span>
              </div>
            </v-col>
          </v-row>
        </v-container>
      </v-card-text>
      <v-divider></v-divider>
      <v-card-actions class="px-5 mt-0">
        <v-spacer></v-spacer>
        <v-btn text @click="cancel()">{{ $t('btn.cancel') }}</v-btn>
        <v-btn
          text
          class="font-weight-bold"
          color="primary"
          @click="confirm()"
          :loading="loading"
          :disabled="!creditIsSufficient || accountIsDisabled"
        >{{ $t('btn.confirm') }}</v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import { mapActions, mapGetters } from "vuex";

export default {
  props: {
    dialog: {
      type: Object,
      default: function() {
        return {
          display: false
        };
      }
    },
    push: {
      type: Object,
      default: function() {
        return {};
      }
    },
    numbers: {
      type: Object,
      default: function() {
        return {
          valid: [],
          invalid: []
        };
      }
    }
  },
  data: () => ({
    loading: false
  }),
  methods: {
    async confirm() {
      this.notify({ status: false });
      this.loading = true;
      try {
        const response = await this.request({
          url: "/api/providers/bulksms/pushes/simple",
          method: "post",
          data: {
            sender: this.push.sender,
            content: this.push.content,
            numbers: this.numbers.valid
          },
          messages: {
            201: this.$t("bulksms.campaign.simple.create.success"),
            400: true,
            403: true,
            422: error => {
              let message = "error_codes.error_occured";
              if (error.code === "P101") {
                const details = error.details;
                const data = error.data;
                if (details.code === 400) {
                  message = "error_codes.bad_request";
                } else if (details.code === 422) {
                  if (data.message === "error credit") {
                    message = "bulksms.error.credit";
                  }
                }
              }
              return this.$t(message);
            }
          }
        });
        // emit success
        this.$emit("success", response.data);
      } catch (error) {
        // empty
      }
      this.loading = false;
      this.dialog.display = false;
    },
    cancel() {
      this.dialog.display = false;
    },
    numbersCount(numbers) {
      if (numbers) {
        if (numbers["valid"]) {
          return this.$utils.pad(numbers["valid"].length, 2);
        }
      }
      return 0;
    },
    ...mapActions({
      notify: "notification/notify",
      request: "request"
    })
  },
  computed: {
    ...mapGetters({
      bulksmsUser: "bulksms/user",
      accountIsDisabled: "bulksms/userIsDisabled"
    }),
    creditIsSufficient() {
      const numbersCount = this.$objectPath.has(this.numbers, "valid")
        ? this.numbers["valid"].length
        : 0;
      const smsCount = this.$utils.countSms(this.push.content);
      const smsCredit = numbersCount * smsCount;

      if (
        this.bulksmsUser.accountType !== "PostPaid" &&
        smsCredit >
          this.bulksmsUser.balance
      ) {
        return false;
      }

      return true;
    }
  }
};
</script>
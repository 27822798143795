<template>
  <div>
    <div class="d-flex justify-center">
      <v-form autocomplete="off" class="pt-2 campaign-form" @submit.prevent="send()" style="width: 630px;">
        <v-card max-width="630" min-width="300" class="pb-4">
          <v-card-title class="px-7">
            <div class="font-weight-bold text-subtitle-1">
              <div class="secondary-font">{{ $t("bulksms.campaign.simple.create.title") }}</div>
            </div>
          </v-card-title>
          <v-card-subtitle
            class="grey--text px-7 text-body-2"
          >{{ $t("bulksms.campaign.simple.create.description") }}</v-card-subtitle>
          <v-card-text class="px-7 pb-2 pt-3">
            <div class="mb-1 font-weight-bold">{{ $t("bulksms.campaign.fields.sender.title") }} *</div>
            <v-text-field
              v-model="form.sender"
              :placeholder="`${$t(
                'bulksms.campaign.fields.sender.placeholder'
              )} *`"
              @input="delayTouch($v.form.sender)"
              @blur="$v.form.sender.$touch"
              :error-messages="senderErrors"
              autocomplete="off"
              maxlength="11"
              class="font-weight-bold text-body-1"
              outlined
              background-color="fourth"
              counter="11"
              dense
              append-icon="mdi-account-arrow-right-outline"
            ></v-text-field>
            <div
              class="mb-1 font-weight-bold mt-2"
            >{{ $t("bulksms.campaign.fields.content.title") }} *</div>
            <v-textarea
              v-model="form.content"
              :rows="3"
              @input="delayTouch($v.form.content)"
              :placeholder="`${$t(
                'bulksms.campaign.fields.content.placeholder'
              )} *`"
              @blur="$v.form.content.$touch"
              no-resize
              counter="1600"
              :hint="smsCounter + ' SMS'"
              :error-messages="contentErrors"
              persistent-hint
              outlined
              background-color="fourth"
              class="font-weight-bold text-body-1"
              append-icon="mdi-message-text-outline"
            ></v-textarea>
            <div
              class="mb-1 font-weight-bold mt-4"
            >{{ $t("bulksms.campaign.fields.numbers.title") }} *</div>
            <v-textarea
              v-model="form.numbers"
              :rows="4"
              :placeholder="`${$t(
                'bulksms.campaign.fields.numbers.placeholder'
              )} *`"
              @input="delayTouch($v.form.numbers)"
              @blur="$v.form.numbers.$touch"
              no-resize
              :error-messages="numbersErrors"
              class="font-weight-bold text-body-1"
              :hint="
                $t('bulksms.campaign.fields.numbers.hint', {
                  numbers: numbersCount,
                })
              "
              persistent-hint
              outlined
              background-color="fourth"
              append-icon="mdi-contacts-outline"
            ></v-textarea>
            <div
              class="mb-1 font-weight-bold mt-3"
            >{{ $t("bulksms.campaign.fields.send_date.title") }}</div>
            <v-text-field
              @click="displayDatetimePicker()"
              :value="
                form.sendDate
                  ? $t('bulksms.campaign.fields.send_date.scheduled', {
                      date: $moment(form.senDate).format('YYYY-MM-DD'),
                      hour: $moment(form.sendDate).format('HH:mm'),
                    })
                  : $t('now')
              "
              autocomplete="off"
              class="font-weight-bold"
              outlined
              background-color="fourth"
              readonly
              dense
              append-icon="mdi-clock-outline"
              @click:append="displayDatetimePicker"
            ></v-text-field>
          </v-card-text>
          <v-card-actions class="px-7">
            <v-spacer></v-spacer>
            <v-btn type="submit" :disabled="$v.form.$invalid" color="primary">
              <span class="text-subtitle-2 px-2">{{ $t('btn.validate') }}</span>
            </v-btn>
          </v-card-actions>
        </v-card>
        <datetime-picker
          @changed="changeDatetime"
          :dialog="dialog.datetimepicker"
          :datetime="datetimepicker"
        ></datetime-picker>
        <campaign-simple-confirm
          :dialog="dialog.confirm"
          :push="push"
          :numbers="numbers"
          @success="confirmSuccess"
        ></campaign-simple-confirm>
      </v-form>
    </div>
  </div>
</template>

<script>
import Vue from "vue";
import { required, helpers, maxLength } from "vuelidate/lib/validators";
import FormDelayTouchMixin from "./../../../mixins/form-delay-touch";
import { mapActions, mapGetters } from "vuex";
import DatetimePicker from "./../../commons/datetimepicker";
import CampaignSimpleConfirm from "./simple-confirm";

const maxPushContacts = 10000;
const isValidLinePhoneNumbers = function(isLocalUser) {
  return value => {
    if (!helpers.req(value)) return true;

    const linesToArrayNumbers = Vue.$utils.linesToArrayNumbers(
      value,
      isLocalUser
    );
    
    if (linesToArrayNumbers["valid"].length < 1) {
      return false;
    }

    if (linesToArrayNumbers["valid"].length > maxPushContacts) {
      return false;
    }
    return true;
  };
};

export default {
  mixins: [FormDelayTouchMixin],
  metaInfo() {
    return {
      title: this.$t("bulksms.campaign.simple.title")
    };
  },
  data: () => ({
    form: {
      sender: null,
      content: null,
      numbers: null,
      sendDate: null
    },
    push: {},
    datetimepicker: {
      date: null,
      hour: null
    },
    dialog: {
      confirm: {
        display: false
      },
      datetimepicker: {
        display: false
      }
    }
  }),
  validations() {
    return {
      form: {
        sender: {
          required,
          sender: value =>
            !helpers.req(value) || this.$utils.validateSender(value)
        },
        content: {
          required,
          maxLength: maxLength(1600)
        },
        numbers: {
          required,
          numbers: isValidLinePhoneNumbers(this.isLocalUser)
        }
      }
    };
  },
  methods: {
    ...mapActions({
      request: "request",
      notify: "notification/notify",
      refreshBulksmsUser: "bulksms/getUser"
    }),
    changeDatetime(datetime) {
      if (datetime.date && datetime.hour) {
        this.form.sendDate = datetime.date + " " + datetime.hour + ":00";
      } else {
        this.sendDate = null;
      }
    },
    displayDatetimePicker() {
      this.dialog.datetimepicker.display = true;
    },
    send() {
      this.notify({ status: false });
      // validate form
      this.$v.form.$touch();
      if (this.$v.form.$invalid) {
        this.notify({ message: this.$t("error_codes.bad_request") });
        return;
      }
      this.push = this.form;
      this.dialog.confirm.display = true;
    },
    confirmSuccess() {
      // refresh bulksms user
      this.refreshBulksmsUser();
      // reset contacts fields
      this.form.numbers = null;
      this.$v.form.numbers.$reset();
    }
  },
  computed: {
    ...mapGetters({ bulksmsUser: "bulksms/user" }),
    isLocalUser() {
      return this.bulksmsUser.country === "local"
        ? true
        : false;
    },
    senderErrors() {
      const errors = [];

      if (!this.$v.form.sender.$dirty) return errors;

      !this.$v.form.sender.required &&
        errors.push(this.$t("bulksms.campaign.fields.sender.required"));
      !this.$v.form.sender.sender &&
        errors.push(this.$t("bulksms.campaign.fields.sender.invalid"));

      return errors;
    },
    contentErrors() {
      const errors = [];

      if (!this.$v.form.content.$dirty) return errors;

      !this.$v.form.content.required &&
        errors.push(this.$t("bulksms.campaign.fields.content.required"));
      !this.$v.form.content.maxLength &&
        errors.push(
          this.$t("bulksms.campaign.fields.content.max_length", {
            max: this.$v.form.content.$params.maxLength.max
          })
        );

      return errors;
    },
    numbersErrors() {
      const errors = [];

      if (!this.$v.form.numbers.$dirty) return errors;

      !this.$v.form.numbers.required &&
        errors.push(this.$t("bulksms.campaign.fields.numbers.required"));
      !this.$v.form.numbers.numbers &&
        errors.push(this.$t("bulksms.campaign.fields.numbers.invalid", {min: 1, max: maxPushContacts}));

      return errors;
    },
    numbers() {
      return this.$utils.linesToArrayNumbers(
        this.form.numbers,
        this.isLocalUser
      );
    },
    smsCounter() {
      return this.$utils.pad(this.$utils.countSms(this.form.content), 2);
    },
    numbersCount() {
      if (this.numbers) {
        if (this.numbers["valid"]) {
          return this.$utils.pad(this.numbers["valid"].length, 2);
        }
      }
      return 0;
    }
  },
  components: {
    DatetimePicker,
    CampaignSimpleConfirm
  }
};
</script>